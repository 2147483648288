#home {
    position: relative;
    width: 100vw;
    height: 100vh;
    
}

#name {
    color: var(--secondary-color);
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0;

   
}

#primary-header {
    font-size: 4rem;
    text-align: center;
}
